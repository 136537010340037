export const packingNet = {
    "heading" : "Сітка екструдована",
    buttons: {
        "vegetables" : "Для овочів та фруктів",
        "crimping" : "Для гофрування ковбасних оболонок",
        "toys" : "Для упаковки іграшок",
        "glass" : "Для склянної тари",
        "tree" : "Для захисту стовбурів дерев",

    },
    vegatbles: {
        "heading" : "Сітка-рукав для фасування овочів та фруктів",
        "text1" : "Призначена для фасовки овочів/фруктів, вагою до 7 кг",
        "text2" : "Міцність та еластичність забезпечують цілісність товару від моменту пакування до споживача",
        "text3" : "Сітка може застосовуватися як при ручному, так і автоматичному пакуванні",
    },
    crimping: {
        "heading" : "Сітка-рукав для гофрування ковбасних оболонок",
        characteristics:{
            "text":"Технічні характеристики : ",
            "char1":"Ширина рукаву - 190мм",
            "char2":"Міцність на розрив - >390Н",
            "char3":"Макcимальне видовження - 130%",
            "char4":"Внутрішній діаметр шпулі - 50мм",
        },
        "text1" : "Індивідуальний підхід до кожного виробника гарантує високу якість упаковки",
        "text2" : "Ширина сіток відповідає калібру виробників оболонок",
    },
    toys: {
        "heading" : "Сітка-рукав для пакування іграшок",
        "text1" : "Можливий підбір кольору та розміру під конкретну іграшку або їх набір, не зважаючи на розміри",
        "text2" : "Має високі показники міцності та еластичності, через спеціальну технологію виготовлення",
    },
    glass: {
        "heading" : "Захисна сітка-рукав для скляної тари, пляшок, меблів та виробів з металу",
        "text1" : "Розроблена спеціально для захисту: склянних тар, пляшок, меблів...",
        "text2" : "Є ідеальним рішенням для надійного захисту виробів від механічних пошкоджень",
        "text3" : "Привабливий вигляд, через можливість обрати колір та товщину нитки",
    },
    tree : {
        "heading" : "Сітка-рукав для захисту стовбурів дерев",
        "text1" : "Не перешкоджає догляду за деревом, додатково  захищає від снігу, не порушує циркуляцію повітря",
        "text2" : "Забезпечує захис кори саджанців та дерев у зимню пору року від гризунів",
    },
    cards:{
        "heading" : "Переваги",
        "card1 head" : "Міцність",
        "card1 title" : "виготовляється з високоякісного поліетилену, що дозволяє створювати міцну та стійку сітку до навантажень",
        "card2 head" : "Функціональність",
        "card2 title" : "Може бути використана у різних сферах, нариклад: упаковування продуктів, зберігання речей, рукоділля",
        "card3 head" : "Екологічність",
        "card3 title" : "Не має шкідливих речовин і не дає відходів при розкладанні",

    },

}