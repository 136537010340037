export const footerEN = {
    links: {
        "masterbatch":"Masterbatch for polymers",
        "addtions":"Additives to polymers",
        "net":"Extruded net",
        "composites":"Composites",
    },
    info:{
        "addres":"м. Kyiv, 9a Shchekavytska St. ",
    },
    about:{
        "about company":"About us",
        "certificates":"Certificates",
        "our partners":"Our partners",
    }
}