export const helmet = {
    "masterbatch":"Барвники для полілмерів",
    "extruded net":"Сітка екструдована",
    "addivities":"Добавки до полімерів",
    "composites":"Композити",
    "about":"Про компанію",
    "certificates":"Сертифікати",
    "clients":"Клієнти",
    "home":"Головна",
    error404: {
        "notFound":"Сторінка не знайдена",
        "to home page":"На головну",
    }
}