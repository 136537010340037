export const helmet = {
    "masterbatch":"Masterbatches",
    "extruded net":"Nets",
    "addivities":"Adiivities",
    "composites":"Composites",
    "about":"About us",
    "certificates":"Certificates",
    "clients":"Clients",
    "home":"Home page",
    error404: {
        "notFound":"Page not found",
        "to home page":"To home page",
    }
}