export const addivities = {
    "heading": "Добавки до полімерів",
    buttons: {
        "0": "Антиблоки",
        "1": "Антистатики",
        "2": "Уф-стабілізатори",
        "3": "Антиоксидант",
        "4": "Процесинги",
        "5": "Оптичний відбілювач",
        "6": "Чистячі добавки",
        "7": "Вспінюючі добавки"
    },
    "PE 0600 F": {
        "heading": "Антиблок",
        "short": "Застосовується при виробництві плівок",
        meltFlow: {
            "method": "ASTMD – 1238(190°C/2.16 kg)",
            "units": "гр/10 хв",
            "value": "25",
        },
        "polymer": "LLDPE",
        paragraphs: {
            "usage": "Застосовується при виробництві плівок із LDPE, LLDPE, HDPE. Цей продукт рекомендується для виробів з високими вимогами. Значно полегшує процес відкриття рукава та відсутність злипання під час виробництва",
            "dosage": "Відсоток введення залежить від вимог та умов експлуатації кінцевого виробу. Рекомендований відсоток введення 0,5-3%",
            "saving": "Цей продукт рекомендується зберігати, у заводській упаковці, у сухому та прохолодному приміщенні при температурі повітря не вище 35°C, без потрапляння прямих сонячних променів",
        }

    },
    "PE 0601 F": {
        "heading": "Антиблок",
        "short": "Застосовується при виробництві плівок",
        meltFlow: {
            "method": "ASTMD – 1238(190°C/2.16 kg)",
            "units": "гр/10 хв",
            "value": "25",
        },
        "polymer": "LLDPE",
        paragraphs: {
            "usage": "Застосовується для регулювання коефіцієнта тертя та полегшення процесу відкриття рукава при виробництві плівок із LDPE, LLDPE, HDPE. Цей продукт рекомендується для виробів із високими оптичними вимогами. Не призначений для використання у плівках з подальшим нанесенням флексографічного друку та ламінації.",
            "dosage": "Відсоток введення залежить від вимог та умов експлуатації кінцевого виробу. Рекомендований відсоток введення 0,5-3%",
            "saving": "Цей продукт рекомендується зберігати, у заводській упаковці, у сухому та прохолодному приміщенні при температурі повітря не вище 35°C, без потрапляння прямих сонячних променів",
        }
    },
    "PE 0602 F": {
        "heading": "Антиблок",
        "short": "Застосовується при виробництві плівок",
        meltFlow: {
            "method": "ASTMD – 1238(190°C/2.16 kg)",
            "units": "гр/10 хв",
            "value": "25",
        },
        "polymer": "LLDPE",
        paragraphs: {
            "usage": "Застосовується для регулювання коефіцієнта тертя та полегшення процесу відкриття рукава при виробництві плівок із LDPE, LDPE, HDPE. Цей продукт рекомендується для виробів із високими оптичними вимогами. Може використовуватися при виробництві плівок з подальшим нанесенням флексографічного друку та ламінування. Максимальний ефект цієї добавки проявляється через 24 години.",
            "dosage": "Відсоток введення залежить від вимог та умов експлуатації кінцевого виробу. Рекомендований відсоток введення 0,5-3%",
            "saving": "Цей продукт рекомендується зберігати, у заводській упаковці, у сухому та прохолодному приміщенні при температурі повітря не вище 35°C, без потрапляння прямих сонячних променів",
        }

    },
    "PE 0301 F": {
        "heading": "Антистатик",
        "short": "Застосовується для зниження статичного заряду",
        meltFlow: {
            "method": "ASTMD – 1238(190°C/2.16 kg)",
            "units": "гр/10 хв",
            "value": "25",
        },
        "polymer": "LLDPE",
        paragraphs: {
            "usage": "Застосовується для зниження значення статичної електрики на поверхні виробів із термопластів. Цей продукт рекомендований для використання у плівках з подальшим нанесенням флексографічного друку та ламінації з дотриманням відсотка введення. Важливо, щоб під час використання добавки, вологість повітря, у приміщенні, була більше 50%",
            "dosage": "Відсоток введення залежить від вимог та умов експлуатації кінцевого виробу. Рекомендований відсоток введення 0,5-3%",
            "saving": "Цей продукт рекомендується зберігати, у заводській упаковці, у сухому та прохолодному приміщенні при температурі повітря не вище 35°C, без потрапляння прямих сонячних променів",
        }

    },
    "PE 0317 F": {
        "heading": "Антистатик",
        "short": "Застосовується для зниження статичного заряду",
        meltFlow: {
            "method": "ASTMD – 1238(190°C/2.16 kg)",
            "units": "гр/10 хв",
            "value": "25",
        },
        "polymer": "LLDPE",
        paragraphs: {
            "usage": "Застосовується для зниження значення статичної електрики на поверхні виробів із термопластів. Цей продукт рекомендований для використання у плівках з подальшим нанесенням флексографічного друку та ламінації з дотриманням відсотка введення. Важливо, щоб під час використання добавки, вологість повітря, у приміщенні, була більше 50%",
            "dosage": "Відсоток введення залежить від вимог та умов експлуатації кінцевого виробу. Рекомендований відсоток введення 0,5-3%",
            "saving": "Цей продукт рекомендується зберігати, у заводській упаковці, у сухому та прохолодному приміщенні при температурі повітря не вище 35°C, без потрапляння прямих сонячних променів",
         }

    },
    "PE 0021 F": {
        "heading": "УФ-стабілізатор",
        "short": "Застосовується для регулювання коеф. тертя",
        meltFlow: {
            "method": "ASTMD – 1238(190°C/2.16 kg)",
            "units": "гр/10 хв",
            "value": "25",
        },
        "polymer": "LLDPE",
        
        paragraphs: {
            "usage": "Цей продукт містить 20% УФ-стабілізатора і призначений для використання в LDPE, LLDPE, HDPE плівках.",
            "dosage": "Відсоток введення залежить від товщини виробу та кліматичних умов експлуатації кінцевого виробу. Рекомендований відсоток введення 0,5-5%",
            "saving": "Цей продукт рекомендується зберігати, у заводській упаковці, у сухому та прохолодному приміщенні при температурі повітря не вище 35°C, без потрапляння прямих сонячних променів",
         }

    },
    "PE 0040 F": {
        "heading": "УФ-стабілізатор",
        "short": "Застсовується при виробництві плівок",
        meltFlow: {
            "method": "ASTMD – 1238(190°C/2.16 kg)",
            "units": "гр/10 хв",
            "value": "25",
        },
        "polymer": "LLDPE",
        paragraphs: {
            "usage": "Цей продукт містить 40% УФ-стабілізатора і призначений для використання в LDPE, LLDPE, HDPE плівках, стрічках, PP волоконах",
            "dosage": "Відсоток введення залежить від товщини виробу та кліматичних умов експлуатації кінцевого виробу. Рекомендований відсоток введення 0,5-5%",
            "saving": "Цей продукт рекомендується зберігати, у заводській упаковці, у сухому та прохолодному приміщенні при температурі повітря не вище 35°C, без потрапляння прямих сонячних променів",
         }

    },
    "PP 0029 F": {
        "heading": "УФ-стабілізатор",
        "short": "Застосовується для регулювання коеф. тертя",
        meltFlow: {
            "method": "ASTMD – 1238(230°C/2.16 kg)",
            "units": "гр/10 хв",
            "value": "25",
        },
        liquidityIndex: {
            "method": "ASTMD 1525B ",
            "units": "°C",
            "value": "155",
        },
        "polymer": "PP - гомополімер",
        paragraphs: {
            "usage": "Цей продукт містить 20% УФ-стабілізатора (HALS) та призначений для використання в поліпропіленових продуктах, таких як плівки та стрічки. Рекомендується до використання у прозорих виробах",
            "dosage": "Відсоток введення залежить від товщення виробу та умов експлуатації кінцевого виробу. Рекомендований відсоток введення 2%",
            "saving": "Цей продукт рекомендується зберігати, у заводській упаковці, у сухому та прохолодному приміщенні при температурі повітря не вище 35°C, без потрапляння прямих сонячних променів",
         }
    },
    "PP 0051 F": {
        "heading": "УФ-стабілізатор",
        "short": "Застсовується при виробництві плівок",
        meltFlow: {
            "method": "ASTMD – 1238(230°C/2.16 kg)",
            "units": "гр/10 хв",
            "value": "25",
        },
        liquidityIndex: {
            "method": "ASTMD 1525B ",
            "units": "°C",
            "value": "155",
        },
        "polymer": "PP - гомополімер",
        paragraphs: {
            "usage": "Цей продукт містить 20% УФ стабілізатора і призначений для використання в поліпропіленових продуктах, таких як плівки та стрічки",
            "dosage": "Відсоток введення залежить від товщення виробу та умов експлуатації кінцевого виробу. Рекомендований відсоток введення 1,5%",
            "saving": "Цей продукт рекомендується зберігати, у заводській упаковці, у сухому та прохолодному приміщенні при температурі повітря не вище 35°C, без потрапляння прямих сонячних променів",
         }

    },
    "PE 0802 F": {
        "heading": "Антиоксидант",
        "short": "Знижує окислювальну та термоокислювальної деструкції полімеру",
        meltFlow: {
            "method": "ASTM  D1238 (230°C/2.16 kg)",
            "units": "гр/10 хв",
            "value": "≥ 4",
        },
        destinity: {
            "method": "ASTM D1505",
            "units": "гр/см3",
            "value": "0,9 (+/-5%)",
        },
        heatResistanse: {
            "method": "ASTM D2115",
            "units": "°C",
            "value": "280",
        },
        "polymer": "LLDPE",
        paragraphs: {
            "usage": "Застосовується при  виготовленні виробів з LDPE, LLDPE, HDPE, РР. Уповільнює процеси деструкції полімеру, що виникають під дією високих температур та зсувних напружень (термостабілізація). Подовжує строк експлуатації готового виробу за рахунок зниження окислювальної та термоокислювальної деструкції полімеру",
            "dosage": "Дозування залежить від умов експлуатації кінцевого виробу. Рекомендоване введення - від 0,5% при стандартних умовах",
            "saving": "Даний продукт рекомендовано зберігати в оригінальній упаковці, в сухому та прохолодному приміщенні за температури не вище 50°C, уникаючи потрапляння прямих сонячних променів",
            "appearance":"Сферична або циліндрична, умовно прозора гранула",
        }  


    },
    "PE 0421 F": {
        "heading": "Процесинг",
        "short": "Призначений для збільшення ефективності процеса екструзії",
        meltFlow: {
            "method": "ASTM  D1238 (230°C/2.16 kg)",
            "units": "гр/10 хв",
            "value": "0,91 (+/-30%)",
        },
        destinity: {
            "method": "ASTM D1505",
            "units": "гр/см3",
            "value": "0,92 (+/-5%)",
        },
        heatResistanse: {
            "method": "ASTM D2115",
            "units": "°C",
            "value": "240",
        },
        "polymer": "LLDPE",
        paragraphs: {
            "usage": "Призначений для збільшення ефективності процеса екструзії (поліолефінів) плівок методом роздуву рукава і плоскощілинної екструзії, а також для екструзії профільно-погонажних виробів (труб)",
            "dosage": "Дозування залежить від вимог і умов експлуатації кінцевого виробу. Рекомендований відсоток введення  0,5-1,5% за стандартних умов. Для збільшення ефекту перші 30-60 хвилин роботи екструдера необхідно ввести 3-5% до основної сировини.",
            "appearance":"Циліндрична гранула, яка має відтінок близький до полімерного носія",
            "saving": "Даний продукт рекомендовано зберігати в оригінальній упаковці, в сухому та прохолодному приміщенні за температури не вище 50°C, уникаючи потрапляння прямих сонячних променів. ",
         }
    },
    "PE 0422 F": {
        "heading": "Процесинг",
        "short": "Призначений для збільшення ефективності процеса екструзії",
        meltFlow: {
            "method": "ASTM  D1238 (230°C/2.16 kg)",
            "units": "гр/10 хв",
            "value": "0,91 (+/-30%)",
        },
        destinity: {
            "method": "ASTM D1505",
            "units": "гр/см3",
            "value": "0,92 (+/-5%)",
        },
        heatResistanse: {
            "method": "ASTM D2115",
            "units": "°C",
            "value": "240",
        },
        "polymer": "PE",
        paragraphs: {
            "usage": "Призначений для збільшення ефективності процеса екструзії (поліолефінів) плівок методом роздуву рукава і плоскощілинної екструзії, а також для екструзії профільно-погонажних виробів (труб)",
            "dosage": "Дозування залежить від вимог і умов експлуатації кінцевого виробу. Рекомендований відсоток введення  0,5-1,5% за стандартних умов. Для збільшення ефекту перші 30-60 хвилин роботи екструдера необхідно ввести 3-5% до основної сировини",
            "saving": "Даний продукт рекомендовано зберігати в оригінальній упаковці, в сухому та прохолодному приміщенні за температури не вище 50°C, уникаючи потрапляння прямих сонячних променів",
            "appearance":"Циліндрична гранула, яка має відтінок близький до полімерного носія",
        }

    },
    "PE 0424 F": {
        "heading": "Процесинг",
        "short": "Призначений для збільшення ефективності процеса екструзії",
        meltFlow: {
            "method": "ASTM  D1238 (190°C/2.16 kg)",
            "units": "гр/10 хв",
            "value": "≥ 0,6",
        },
        destinity: {
            "method": "ASTM D1505",
            "units": "гр/см3",
            "value": "1,4 (+/-10%)",
        },
        heatResistanse: {
            "method": "ASTM D2115",
            "units": "°C",
            "value": "240",
        },
        "polymer": "PE",
        paragraphs: {
            "usage": "Призначений для збільшення ефективності процеса екструзії (поліолефінів) плівок методом роздуву рукава і плоскощілинної екструзії, а також для екструзії профільно-погонажних виробів (труб)",
            "dosage": "Дозування залежить від вимог і умов експлуатації кінцевого виробу. Рекомендований відсоток введення  0,5-1,5% за стандартних умов. Для збільшення ефекту перші 30-60 хвилин роботи екструдера необхідно ввести 3-5% до основної сировини",
            "saving": "Даний продукт рекомендовано зберігати в оригінальній упаковці, в сухому та прохолодному приміщенні за температури не вище 50°C, уникаючи потрапляння прямих сонячних променів",
            "appearance":"Циліндрична гранула білого відтінку",
        }

    },
    "PE 0103 F": {
        "heading": "Оптичний відбілювач",
        "short": "Підвищує стійкість до руйнівного дії УФ",
        meltFlow: {
            "method": "ASTM  D1238 (190°C/2.16 kg)",
            "units": "гр/10 хв",
            "value": "20",
        },
        "polymer": "LLDPE",
        paragraphs: {
            "usage": "Рекомендується під час переробки вторинного поліетилену. Нівелює жовтуватий відтінок, внаслідок чого колір матеріалу стає близьким до первинної сировини. Введення оптичного відбілювача в полімерні матеріали підвищує стійкість до руйнівної дії УФ радіації готового виробу",
            "dosage": "Рекомендований відсоток введення знаходиться в межах від 0,3% до 1,5% залежно від вимог до готового виробу",
            "saving": "Цей продукт рекомендується зберігати, у заводській упаковці, у сухому та прохолодному приміщенні при температурі повітря не вище 35°C, без потрапляння прямих сонячних променів",
         }

    },
    "PE 0101 F": {
        "heading": "Чистяча добавка",
        "short": "Застосовується при очищенні робочих елементів в екструзійному і литтєвому обладнанні",
        meltFlow: {
            "method": "ASTM  D1238 (190°C/2.16 kg)",
            "units": "гр/10 хв",
            "value": "≥ 15",
        },
        destinity: {
            "method": "ASTM D1505",
            "units": "гр/см3",
            "value": "1,1(+/-10%)",
        },
        heatResistanse: {
            "method": "ASTM D2115",
            "units": "°C",
            "value": "160",
        },
        "polymer": "LLDPE",
        paragraphs: {
            "usage": "Застосовується при очищенні робочих елементів в екструзійному і литтєвому обладнанні. Застосовується під час зміні полімеру чи під час переходу на інший колір. Також використовується, як консервант для попередження термодеструкції під час зупинки і повторному запуску екструдера",
            "dosage": "Рекомендується заповнити шнек готовою сумішью і зупинити на 30-60 хвилин, температура суміші не повинна перевищувати робочу на 20-500C. Після чого промити екструдер чистим полімером. Рекомендований відсоток вводу 50-100%.",
            "saving": "Даний продукт рекомендовано зберігати в оригінальній упаковці, в сухому та прохолодному приміщенні за температури не вище 50°C, уникаючи потрапляння прямих сонячних променів",
            "appearance":"Циліндрична гранула, яка має жовтий відтінок, характерний для використаних активних речовин",
        }

    },
    "PE 0706 F": {
        "heading": "Вспінююча добавка",
        "short": "Застосовується для отримання вспіненних і \"підвспіненних\" полімерних виробів",
        meltFlow: {
            "method": "ASTM  D1238 (190°C/2.16 kg)",
            "units": "гр/10 хв",
            "value": "≥ 10",
        },
        destinity: {
            "method": "ASTM D1505",
            "units": "гр/см3",
            "value": "0,9 (+/-10%)",
        },
        heatResistanse: {
            "method": "ASTM D2115",
            "units": "°C",
            "value": "140",
        },
        "polymer": "LLDPE",
        paragraphs: {
            "usage": "Застосовується для отримання вспіненних і \"підвспіненних\" полімерних виробів. Добавка на основі азодікарбонаміда, що розкладається при нагріванні з виділенням великої кількості газів. \"Підвспінення\" дозволяє усунути всідання, короблення, а також знизити вагу виробу",
            "dosage": "Дозування залежить від вимог і умов експлуатації кінцевого виробу. Рекомендований відсоток введення  0,5-1,5% за стандартних умов. Для збільшення ефекту перші 30-60 хвилин роботи екструдера необхідно ввести 3-5% до основної сировини",
            "saving": "Даний продукт рекомендовано зберігати в оригінальній упаковці, в сухому та прохолодному приміщенні за температури не вище 35°C, уникаючи потрапляння прямих сонячних променів",
            "appearance":"Циліндрична гранула жовтого відтінку",
         }
    },
    popUp: {
        "article": "Артикул: ",
        "viscosity index ": "Індекс дягучості(полімерносій):",
        "method": "Метод випробування:",
        "usage": "Застосування:",
        "dosage": "Дозування: ",
        "saving": "Зберігання:",
        "appearance": "Зовнішній вигляд",
        table: {
            "properties": "Властивості",
            "testMethods": "Метод випробувань",
            "units": " Одиниці виміру",
            "value": "Значення",

            "meltFlow": "Показник плинності розплаву (ППР)",
            "destinity": "Густина",
            "heatResistanse": "Термостійкість",
            "polymer carrier": "Полімерний носій",
            "foodContact": "Контакт з харчовими продуктами",
        }
    },

    "text1" : "Добавки для полімерів застосовуються, як лекий та економний спосіб зміни властивостей полімерів, тощо. Містять модифікатори або їх комбінації в необхідних пропорціях, відповідно до рецептури. Мають відмінну дисперсію у відповідній полімерній основі.",
    "text2" : "Слід зазначити, що існують суперконцентрати – це комбінації барвників і добавок для полімерів. Їх перевага, в спрощенні контролю відтінку при дотриманні дозування.",



}                
