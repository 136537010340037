export const footerUK = {
    links: {
        "masterbatch":"Барвники для полімерів",
        "addtions":"Добавки до полімерів",
        "net":"Сітка екструдована",
        "composites":"Композити",
    },
    info:{
        "addres":"м. Київ, вул. Щекавицька 9-а ",
    },
    about:{
        "about company":"Про компанію",
        "certificates":"Сертифікати",
        "our partners":"Наші партнери",
    }
}