export const routes = {
    MASTERBATCH : '/masterbatch',
    PACKING_NET : "/net",
    ADDITIVES : "/addivities",
    COMPOSITES : "/composites",
    MAIN: "/",
    ABOUT: '/about',
    CERTIFICATES : '/certificates',
    CLIENTS: '/clients',
    RU:'/ru*',
    EN:'/en*',
    UA:'/ua*'
}