export const ImagesAlternative = {
    homepage:{
        "masterbatch":"Барвники для полімерів",
        "net":"Сітка екструдована",
        "addivities":"Добавки до полімерів",
        "composites":"Композити",
    },
    masterbatcPage:{
        "colors":"Кольорові барвники для полімерів",
        "white":"Білі барвники для полімерів",
        "black":"Чорні барвники для полімерів",
    },
    nets:{
        "vegetables":"Сітка-рукав для фасування овочів та фруктів",
        "crimping":"Сітка-рукав для гофрування ковбасних оболонок",
        "toys":"Сітка-рукав для пакування іграшок",
        "glass":"Захисна сітка-рукав",
        "trees":"Сітка - рукав для захисту стовбурів дерев",
    },
    about:{
        "main":"Київ",

    }
}