export const about = {
    "subheading":"– надійний виробник високоякісних технологічних добавок, суперконцентратів для виробництва полімерних виробів",
    info:{
        "guarantees1":"Підприємство Taycoon на ринку вже більше 25-ти років, тому ми знаємо, що таке якість та надійність. Наше виробництво відповідає стандартам ",
        "guarantees2":"та успішно пройшло ресертифікацію 2023 року",
        "factory":"Спеціалізуємось на виробництві високоякісних добавок, композитів, суперконцентратів призначених для виробництва полімерних виробів. Також виготовляємо екструдовану сітку-рукав",
        "advantage":"Наша ключова перевага - індивідуальний підхід до кожного клієнта. Менеджери готові надати професійну консультацію та виготовити спеціалізовану сітку чи добавку, яка відповідає замовленню . Процес розробки та виготовлення буде супроводжуватись нашим технологічним експертом",
        "lab":"Маємо власну лабораторію для синтезу кольорів та контролю якості, згідно замовлення клієнта. Після оформлення замовлення, у короткий термін, буде розроблена рецептура, надані зразки згідно ваших вимог ",
        "ukr":"Ми – Український виробник, тому швидкість постачання продукту буде значно вищою, ніж з-за кордону. Якість вироблених нами товарів відповідає європейським стандартам, оскільки виробляється на інноваційному обладнанні німецької компанії «LEISTRITZ Extrusionstechnik GmbH» та проходить ретельну перевірку",
    },
    eco:{
        "heading":"Екологія",
        "p1":"Екологічна політика нашого підприємства базується на принципі зменшення негативного впливу виробництва на довкілля. ",
        "p2":"Ми ретельно обираємо сировину для виробництва добавок, суперконцентратів барвників та пакувальної сітки-рукав, віддаючи перевагу тим, які мають менший екологічний вплив",
        "p3":"Підприємство працює та розвиває безвідходне виробництво прагнучи до максимального використання ресурсів і попередження утворення зайвих відходів. Наші технологи розробили композитний матеріал в якому частка полімеру зменшена за рахунок застосування відходів деревини",
        "p4":"Нам важливо оптимізувати використання сировини, енергії, води та інших ресурсів, зменшуючи їх витрати і негативний вплив на довкілля. Для зменшення викидів в повітря на виробництві встановлена газоочисна установка з тканинним фільтром SFC 100-40, призначеним для очистки газоповітряної суміші від суспендованих твердих частинок, що утворюються в процесі виробництва",
        "p5":"На підприємстві регулярно проводяться навчання персоналу щодо екологічної безпеки. Наші технологи працюють над розробкою більш екологічно чистих продуктів, які мають менший вплив на довкілля.",
        "p6":" Підприємство визнає свою відповідальність за власні дії і їх вплив на довкілля.",

    },
    img:{
        "menu" : "Tycoon",
        "guarantees":"Гарантії",
        "factory":"Виробництво",
        "advantage":"Перевага",
        "lab":"Лабораторія",
        "ukr":"прапор України",
        "eco":"Природа",
    }

}